<template>
  <div class="title">
    <div class="title_box">
      <div class="title_logo">
        <a class="navbar-brand" href="#">
          <img
            src="../../assets/images/logo.png"
            width="100"
            class="logo"
            alt=""
          />
        </a>
      </div>
      <div class="title_ul">
        <ul>
          <li
            @click="toHome"
            @mouseover="showHom"
            @mouseout="closeHom"
            :style="NowPath == 1000 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
          >
            首页
          </li>
          <li @mouseover="showAbo = true" @mouseout="showAbo = false">
            关于我们
          </li>
          <li @mouseover="showPro = true" @mouseout="showPro = false">
            产品中心
          </li>
          <li
            :style="NowPath == 998.2 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
            @click="toMovie"
          >
            新闻资讯
          </li>
          <li
            :style="NowPath == 998.1 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
            @click="toVideoCenter"
          >
            产品视频
          </li>
          <!-- <li @click="toJob">人才招聘</li>
          <li @click="toContactUs">联系我们</li> -->
        </ul>
      </div>

      <!-- 解决悬浮不显示下拉框的问题 -->
      <div class="title_uls">
        <ul>
          <li
            class="list1"
            @mouseover="showAbo = true"
            @mouseout="showAbo = false"
          ></li>
          <li
            class="list2"
            @mouseover="showPro = true"
            @mouseout="showPro = false"
          ></li>
          <li
            class="list3"
            @mouseover="showInfo = true"
            @mouseout="showInfo = false"
          ></li>
        </ul>
      </div>
    </div>

    <!-- 悬浮显示下拉菜单 产品系列 -->
    <div
      class="pulldownList"
      @mouseover="showPro = true"
      @mouseout="showPro = false"
      v-show="showPro"
    >
      <div class="product">
        <div
          class="product_dt"
          v-for="(item, index) in ProductList"
          :key="index"
          @click="toProductLine(index)"
        >
          <div
            v-for="(ite, ind) in item.categoryPicture"
            :key="ind"
            @click="changeProduct(index)"
          >
            <img :src="ite.mediaUrl" alt="" />
            <p
              :style="NowPath == index ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
            >
              {{ item.categoryName }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 关于我们 -->
    <div
      class="aboutList"
      @mouseover="showAbo = true"
      @mouseout="showAbo = false"
      v-show="showAbo"
    >
      <ul>
        <li
          @click="toCoreAdvantage"
          :style="NowPath == 999.1 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
        >
          核心优势
        </li>
        <li
          @click="toAboutUs"
          :style="NowPath == 999.2 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
        >
          公司介绍
        </li>
        <li
          @click="toContactUs"
          :style="NowPath == 999.3 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
        >
          联系我们
        </li>
        <li
          @click="toJob"
          :style="NowPath == 999.4 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
        >
          加入我们
        </li>
      </ul>
    </div>

    <!-- <div
      class="infoList"
      @mouseover="showInfo = true"
      @mouseout="showInfo = false"
      v-show="showInfo"
    >
      <ul>
        <li
          @click="toVideoCenter"
          :style="NowPath == 998.1 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
        >
          视频中心
        </li>
        <li
          @click="toMovie"
          :style="NowPath == 998.2 ? 'color:red' : 'color:rgba(0, 0, 0, 1)'"
        >
          媒体报道
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { GetDeviceSeries } from "@/api/api";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "TitleCom",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const showPro = ref(false); //产品下拉框
    const showAbo = ref(false); //关于我们下拉框
    const showInfo = ref(false); //新闻资讯
    const NowPro = ref(1); //标红文字
    const NowPath = ref(1); //标红文字

    const ProductList = ref([]); //筛选有子产品的系列
    console.log(NowPath.value);

    onMounted(() => {
      const name = route.name;
      console.log(name, "路由信息");
      if (name == "home") {
        NowPath.value = 1000;
      } else if (name == "CoreAdvantage") {
        NowPath.value = 999.1;
      } else if (name == "AboutUs") {
        NowPath.value = 999.2;
      } else if (name == "ContactUs") {
        NowPath.value = 999.3;
      } else if (name == "jobfair") {
        NowPath.value = 999.4;
      } else if (name == "VideoCenter") {
        NowPath.value = 998.1;
      } else if (name == "MediaCenter") {
        NowPath.value = 998.2;
      } else if (name == "ProductLine") {
        NowPath.value = 1001;
        console.log(NowPro.value, "产品详情");
      }
      // 获取产品系列数据
      GetDeviceSeries().then((res) => {
        console.log(res, "产品系列数据");
        // ProductList
        if (res && res.code == 0) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].deviceDTOList != null) {
              if (res.data[i].categoryPicture[0].mediaName.slice(-3) == "png") {
                res.data[i].categoryPicture[0].mediaName =
                  res.data[i].categoryPicture[0].mediaName.match(
                    /(.*?)_\d+\.png/
                  )[1];
              } else if (
                res.data[i].categoryPicture[0].mediaName.slice(-3) == "jpg"
              ) {
                res.data[i].categoryPicture[0].mediaName =
                  res.data[i].categoryPicture[0].mediaName.match(
                    /(.*?)_\d+\.jpg/
                  )[1];
              } else {
                res.data[i].categoryPicture[0].mediaName =
                  res.data[i].categoryPicture[0].mediaName.match(
                    /(.*?)_\d+\.gif/
                  )[1];
              }

              ProductList.value.push(res.data[i]);
            }
          }
          // console.log(ProductList.value, "筛选后的系列");
        } else {
          if (res.msg) {
            ElMessage.error(res.msg);
          } else if (res.data.msg) {
            ElMessage.error(res.data.msg);
          }
        }
      });
    });
    // 首页 点击变红
    function showHom() {
      if (NowPath.value != 1000) {
        NowPath.value = 1000;
      }
    }

    // 首页 点击变红
    function closeHom() {
      const name = route.name;
      if (name == "home") {
        NowPath.value = 1000;
      } else if (name == "CoreAdvantage") {
        NowPath.value = 999.1;
      } else if (name == "AboutUs") {
        NowPath.value = 999.2;
      } else if (name == "ContactUs") {
        NowPath.value = 999.3;
      } else if (name == "jobfair") {
        NowPath.value = 999.4;
      } else if (name == "VideoCenter") {
        NowPath.value = 998.1;
      } else if (name == "MediaCenter") {
        NowPath.value = 998.2;
      } else if (name == "ProductLine") {
        NowPath.value = 1001;
        console.log(NowPro.value, "产品详情");
      }
    }
    // 产品详情 点击变红
    function changeProduct(e) {
      NowPro.value = e;
      console.log(e, 123);
      NowPath.value = e;
    }

    // 跳转 首页
    function toHome() {
      router.push({ path: "/" });
    }
    // 人才招聘
    function toJob() {
      router.push({ path: "/jobfair" });
    }
    // 视频中心
    function toVideoCenter() {
      router.push({ path: "/VideoCenter" });
    }
    // 资讯中心
    function toMovie() {
      router.push({ path: "/MediaCenter" });
    }
    // 公司介绍
    function toAboutUs() {
      router.push({ path: "/AboutUs" });
    }
    // 联系我们
    function toContactUs() {
      router.push({ path: "/ContactUs" });
    }
    // 核心优势
    function toCoreAdvantage() {
      router.push({ path: "/CoreAdvantage" });
    }
    // 产品系列
    function toProductLine(e) {
      // console.log(route.path, "获取当前路由");
      if (route.path == "/ProductLine") {
        const val = "section" + e;
        const targetElement = document.getElementById(val.toString());
        // console.log(targetElement, "targetElement");
        if (targetElement) {
          // targetElement.scrollIntoView({ behavior: "smooth", block: "start" ,inline:"nearest"});
          const targetPosition =
            targetElement.getBoundingClientRect().top +
            window.pageYOffset -
            100;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
        } else {
          console.log("未找到匹配的元素");
        }
      } else {
        // console.log(e,'e')
        router.push({
          path: `/ProductLine`,
          query: {
            scrollTo: e,
          },
        });
      }
    }

    return {
      showPro,
      showAbo,
      showInfo,
      toHome,
      toJob,
      toVideoCenter,
      toMovie,
      toAboutUs,
      toContactUs,
      toCoreAdvantage,
      toProductLine,
      ProductList,
      NowPath,
      showHom,
      closeHom,
      changeProduct,
      NowPro,
    };
  },
});
</script>

<style scoped lang="less">
@import "@/components/styles/home/TitleCom.less";
</style>
