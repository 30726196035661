<template>
  <div id="CarouselCom">
    <div class="banner_box">
      <!-- 轮播图 -->
      <el-carousel
        ref="carousel"
        indicator-position="none"
        arrow="never"
        class="banner"
      >
        <!-- 自定义 prev 按钮 -->
        <span slot="prev" class="custom_prev" @click="prevSlide">
          <img src="../../assets/img_home/prev.png" alt="" />
        </span>
        <!-- 自定义 next 按钮 -->
        <span slot="next" class="custom_next" @click="nextSlide">
          <img src="../../assets/img_home/next.png" alt="" />
        </span>

        <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
          <img class="banner_img" :src="item.mediaUrl" alt="" />
          <!-- 轮播图内容 -->
          <div class="banner_content">
            <!-- <p class="p1">Al视觉识别系统</p>
          <p class="p2">Al全自动 大田直播 机器人</p>
          <p class="p3">全电驱动 智能作业</p> -->
            <div class="content_more" v-show="show">
              <div class="more" @click="toDetail(item.beRelatedId)">
                <p>了解更多</p>
                <img src="@/assets/img_home/skip.png" alt="" />
              </div>
              <div class="reserve" @click="toReserve">
                <p>立即预定</p>
                <img src="@/assets/img_home/skip.png" alt="" />
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { GetHomeCarousel } from "@/api/api";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "CarouselCom",
  setup() {
    const router = useRouter();
    const show = ref(true);
    const bannerImg = ref([]);
    const aaa = ref("");
    onMounted(() => {
      // 获取轮播图数据
      GetHomeCarousel({}).then((res) => {
        // console.log(res)
        if (res && res.code == 0) {
          bannerImg.value = res.data;
        } else {
          if (res.msg) {
            ElMessage.error(res.msg);
          } else if (res.data.msg) {
            ElMessage.error(res.data.msg);
          }
        }
      });
    });

    function prevSlide() {
      // 调用 el-carousel 组件的 prev 方法切换到前一个图片
      this.$refs.carousel.prev();
      show.value = false;
      // 使用setTimeout设置延迟，等动画结束后再改变show的状态
      setTimeout(() => {
        show.value = true;
      }, 500); // 设置500毫秒的延迟，可以根据实际情况调整
    }
    function nextSlide() {
      // 调用 el-carousel 组件的 next 方法切换到下一个图片
      this.$refs.carousel.next();
      show.value = false;
      // 使用setTimeout设置延迟，等动画结束后再改变show的状态
      setTimeout(() => {
        show.value = true;
      }, 500); // 设置500毫秒的延迟，可以根据实际情况调整
    }
    function toReserve() {
      router.push({
        path: "/Reserve",
        query: {
          id: 888,
        },
      });
    }
    const toDetail = (e) => {
      console.log(e);
      if (e) {
        router.push({
          path: "/LineDetail",
          query: {
            id: e,
          },
        });
      } else {
        ElMessage.warning('当前图片未绑定设备');
      }
    };
    return {
      bannerImg,
      prevSlide,
      nextSlide,
      toReserve,
      show,
      toDetail,
    };
  },
});

// import { ref } from "vue";
// import { useRouter } from "vue-router";

// const router = useRouter();
// const bannerImg = ref([
//   1, 2, 3, 4,
//   // "../../assets/img_home/banner.png",
//   // "../../assets/img_home/banner.png",
//   // "../../assets/img_home/banner.png",
//   // "../../assets/img_home/banner.png",
//   // 网络地址渲染
//   // "http://5b0988e595225.cdn.sohucs.com/images/20200203/f2507197411547d9bde470f2085075e9.jpeg",
//   // "http://5b0988e595225.cdn.sohucs.com/images/20200203/f2507197411547d9bde470f2085075e9.jpeg",
//   // "http://5b0988e595225.cdn.sohucs.com/images/20200203/f2507197411547d9bde470f2085075e9.jpeg",
//   // "http://5b0988e595225.cdn.sohucs.com/images/20200203/f2507197411547d9bde470f2085075e9.jpeg",
// ]);

// const toReserve = () => {
//   router.push({
//     path: "",
//   });
// };
// const prevSlide = (carousel: { prev: () => void }) => {
//   // 调用 el-carousel 组件的 prev 方法切换到前一个图片
//   carousel.prev();
// };
// const nextSlide = (carousel: { next: () => void }) => {
//   // 调用 el-carousel 组件的 next 方法切换到下一个图片
//   carousel.next();
// };
</script>
<style scoped lang="less">
@import "@/components/styles/home/CarouselCom.less";
</style>
