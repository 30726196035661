// import http from "@/api/http";
// // 接口信息

// // GET
// // export function MessageBoard(params){
// //     return http.get('/sweet/book',{params})
// // }

// // POST
// export function MessageBoard(params) {
//   return http.post("/sweet/book", params);
// }

import axios from "axios";

// 创建一个axios实例
const instance = axios.create({
  headers: {
    "Content-Type": "application/json", // 设置Content-Type为json
  },
  // baseURL: "http://192.168.200.115:8080/renren-admin", // 本地
  // baseURL: "http://test.sweetai.cn:8081/renren-admin", // 测试
  // www.sweetai.cn

  baseURL: "https://www.sweetai.cn/renren-admin", // 线上
  // baseURL: "https://sweetai.cn:443/renren-admin", // 线上


  timeout: 5000, // 请求超时时间，单位：ms
});

// 设置请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做一些处理，例如添加Token等
    return config;
  },
  (error) => {
    // 处理请求错误
    console.error("请求错误:", error);
    return Promise.reject(error);
  }
);

// 设置响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做一些处理
    return response.data;
  },
  (error) => {
    // 处理响应错误
    console.error("响应错误:", error);
    return Promise.reject(error);
  }
);

// export const MessageBoard = (params) => {
//   return instance.get('/sweet/book',params);
// };

// // 留言
// export const MessageBoard = (params) => {
//   return instance.post("/sweet/book", params);
// };

// export const updateUser = (id, user) => {
//   return instance.put(`/users/${id}`, user);
// };

// export const deleteUser = (id) => {
//   return instance.delete(`/users/${id}`);
// };

// 查看首页轮播图
export const GetHomeCarousel = (params) => {
  return instance.get("/sweet/mediarelate/selectHomePageCarouselPicture", params);
};

// 查看首页图文
export const GetHomeImgText = (params) => {
  return instance.get("/sweet/mediarelate/selectHomeArticleAndPicture", params);
};

// 留言
export const MessageBoard = (params) => {
  return instance.post("/sweet/book", params);
};

// 获取招聘人信息
export const GetContactPerson = () =>{
  return instance.get("/sweet/recruitment/getContact")
}

// 招聘信息
export const GetRecruitment = () =>{
  return instance.get("/sweet/recruitment/page")
}

// 产品系列数据
export const GetDeviceSeries = () =>{
  return instance.get("/sweet/device/getDeviceSeries?state=1")
}

// 根据产品id查看产品详情
export const GetDeviceDetail = (id) =>{
  return instance.get(`/sweet/device/detail/${id}`)
}

// 视频中心
export const GetVideoList = (a,b,c) =>{
  return instance.get(`/sweet/video/page?admin=${a}&limit=${b}&page=${c}`)
}

// 媒体报导
export const GetMediaList = (a,b,c) =>{
  return instance.get(`/sweet/article/page?admin=${a}&limit=${b}&page=${c}`)
}

// 获取新闻详情
export const GetMediaDetail = (id) =>{
  return instance.get(`/sweet/article/detail/${id}`)
}

// 获取核心优势数据
export const GetKernel = () =>{
  return instance.get("/sweet/mediarelate/getCoreAdvantages")
}

// 获取企业介绍
export const GetAboutUs = () =>{
  return instance.get("/sweet/mediarelate/getCompanyIntroduction")
}