<template>
  <div id="AllFooter">
    <!-- 简介 -->
    <div class="intro">
      <!-- 内容 -->
      <div class="intro_content">
        <ul style="cursor: pointer">
          <li class="li">产品</li>
          <div class="productList">
            <li
              v-for="(item, index) in ProductList"
              :key="index"
              @click="toProductLine(index)"
            >
              {{ item.categoryName }}
            </li>
          </div>
        </ul>
        <ul style="cursor: pointer">
          <li class="li">关于点甜</li>
          <div class="productList">
            <li class="li" @click="toAboutUs">公司简介</li>
            <li class="li" @click="toCoreAdvantage">核心优势</li>
            <li class="li" @click="toJob">加入我们</li>
            <li class="li" @click="toContactUs">联系我们</li>
          </div>
        </ul>
        <ul style="cursor: pointer">
          <li class="li">新闻咨询</li>
          <div class="productList">
            <li @click="toVideoCenter">视频中心</li>
            <li @click="toMovie">媒体报道</li>
          </div>
        </ul>

        <div class="QRcdoe">
          <div class="weixin">
            <img src="@/assets/img_home/wx.png" alt="" />
            <p>微信视频号</p>
          </div>
          <div class="douyin">
            <img src="@/assets/img_home/dy.png" alt="" />
            <p>抖音视频号</p>
          </div>
          <div class="kefu">
            <img src="@/assets/img_home/gzh.png" alt="" />
            <p>人工客服</p>
          </div>
        </div>
      </div>
      <!-- logo 等 -->
      <div class="logo">
        <img class="img1" src="@/assets/img_home/footer_logo.png" alt="" />
        <img class="img2" src="@/assets/img_home/footer_logos.png" alt="" />
        <p>中国大陆/简体中文</p>
      </div>
    </div>

    <!-- 备案 -->
    <a class="project_Rt_icp_" @click="handle"
      >ICP备案/许可证号：沪ICP备2021006526号-1</a
    >
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { GetDeviceSeries } from "@/api/api";

export default defineComponent({
  name: "AllFooter",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const ProductList = ref([]); //筛选有子产品的系列

    //  run 产品系列
    function toProductLine(e) {
      if (route.path == "/ProductLine") {
        const val = "section" + e;
        const targetElement = document.getElementById(val.toString());
        if (targetElement) {
          const targetPosition =
            targetElement.getBoundingClientRect().top +
            window.pageYOffset -
            100;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
        } else {
          console.log("未找到匹配的元素");
        }
      } else {
        // console.log(e,'e')
        router.push({
          path: `/ProductLine`,
          query: {
            scrollTo: e,
          },
        });
      }
    }

    onMounted(() => {
      // 获取产品系列数据
      GetDeviceSeries().then((res) => {
        console.log(res, "产品系列数据");
        // ProductList
        if (res && res.code == 0) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].deviceDTOList != null) {
              if (res.data[i].categoryPicture[0].mediaName.slice(-3) == "png") {
                res.data[i].categoryPicture[0].mediaName =
                  res.data[i].categoryPicture[0].mediaName.match(
                    /(.*?)_\d+\.png/
                  )[1];
              } else if (
                res.data[i].categoryPicture[0].mediaName.slice(-3) == "jpg"
              ) {
                res.data[i].categoryPicture[0].mediaName =
                  res.data[i].categoryPicture[0].mediaName.match(
                    /(.*?)_\d+\.jpg/
                  )[1];
              } else {
                res.data[i].categoryPicture[0].mediaName =
                  res.data[i].categoryPicture[0].mediaName.match(
                    /(.*?)_\d+\.gif/
                  )[1];
              }

              ProductList.value.push(res.data[i]);
            }
          }
        } else {
          if (res.msg) {
            ElMessage.error(res.msg);
          } else if (res.data.msg) {
            ElMessage.error(res.data.msg);
          }
        }
      });
    });

    // 公司介绍
    function toAboutUs() {
      router.push({ path: "/AboutUs" });
    }

    // 核心优势
    function toCoreAdvantage() {
      router.push({ path: "/CoreAdvantage" });
    }

    // 人才招聘
    function toJob() {
      router.push({ path: "/jobfair" });
    }

    // 联系我们
    function toContactUs() {
      router.push({ path: "/ContactUs" });
    }

    // 视频中心
    function toVideoCenter() {
      router.push({ path: "/VideoCenter" });
    }

    // 资讯中心
    function toMovie() {
      router.push({ path: "/MediaCenter" });
    }
    return {
      toProductLine,
      toAboutUs,
      toCoreAdvantage,
      toJob,
      toContactUs,
      toVideoCenter,
      toMovie,
      ProductList,
    };
  },
});
</script>

<style lang="less" scoped>
@import "@/components/styles/footer/AllFooter.less";
</style>
