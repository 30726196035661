<template>
  <div>
    <!-- 头部 -->
    <TitleCom /> 
    <!-- 轮播图 -->
    <CarouselCom />
    <!-- 内容 -->
    <projectCom />
    <!-- d底部 -->
    <AllFooter/>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import TitleCom from "@/components/home/TitleCom.vue"; // @ is an alias to /src
import CarouselCom from "@/components/home/CarouselCom.vue";
import projectCom from "@/components/home/projectCom.vue";
import AllFooter from "@/components/footer/AllFooter.vue"

export default defineComponent({
  name: "HomeView",
  components: {
    TitleCom,
    CarouselCom,
    projectCom,
    AllFooter
  },
  methods: {
    handle() {
      let href = "https://beian.miit.gov.cn/#/Integrated/recordQuery"; //网站链接
      window.open(href, "_blank");
    },
  },
  created(){
    window.scrollTo(0, 0)
  }
});
</script>
<style lang="less" scoped>
</style>
