import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  
  {
    path:'/about',
    name:'about',
    component: () => import('@/views/AboutView.vue')
  },
  // 备案
  {
    path:'/contact',
    name:'contact',
    component: () => import('@/views/ContactView.vue')
  },
  // 人才招聘
  {
    path:'/jobfair',
    name:'jobfair',
    component: () => import('@/views/JobFair.vue')
  },
  // 媒体报道
  {
    path:'/MediaCenter',
    name:'MediaCenter',
    component: () => import('@/views/MediaCenter.vue')
  },
  // 公司介绍
  {
    path:'/AboutUs',
    name:'AboutUs',
    component: () => import('@/views/AboutUs.vue')
  },
  // 联系我们
  {
    path:'/ContactUs',
    name:'ContactUs',
    component: () => import('@/views/ContactUs.vue')
  },
  // 核心优势
  {
    path:'/CoreAdvantage',
    name:'CoreAdvantage',
    component: () => import('@/views/CoreAdvantage.vue')
  },
  // 新闻资讯 视频中心
  {
    path:'/VideoCenter',
    name:'VideoCenter',
    component: () => import('@/views/VideoCenter.vue')
  },
  // 产品系列
  {
    path:'/ProductLine',
    name:'ProductLine',
    component: () => import('@/views/ProductLine.vue')
  },
  // 产品详情
  {
    path:'/LineDetail',
    name:'LineDetail',
    component: () => import('@/components/Product/LineDetail.vue')
  },
  // 预定 predeterMine
  {
    path:'/Reserve',
    name:'Reserve',
    component: () => import('@/views/predeterMine.vue')
  },
  // 媒体详情
  {
    path:'/MediaDetail',
    name:'MediaDetail',
    component: () => import('@/components/Media/MediaDetail.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
