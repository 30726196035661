<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<style>
/* 隐藏标准的滚动条 */
html::-webkit-scrollbar {
  width: 0;
}
body::-webkit-scrollbar {
  width: 0;
}
/* 隐藏 IE 和 Edge 浏览器的滚动条 */
::-ms-scrollbar {
  width: 0;
}
li {
  list-style: none;
}
* {
  margin: 0;
  padding: 0;
}
</style>
