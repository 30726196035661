<template>
  <!-- 机器人内容 核心优势 新闻资讯 -->
  <div id="projectCom">
    <div class="combination">
      <div class="title">{{ GraphicData.title }}</div>
      <!-- <p class="describe">
        四大板块Farmbot分别为<span style="color: rgba(212, 48, 48, 1)"
          >大田AI全数字器人作业生态系统</span
        >、<span style="color: rgba(212, 48, 48, 1)"
          >果园AI机器人全数字作业生态系统</span
        >、<span style="color: rgba(212, 48, 48, 1)"
          >蔬菜AI机器人全数字作业生态系统</span
        >和<span style="color: rgba(212, 48, 48, 1)"
          >特种山地作业AI机器人生态系统</span
        >，系统可以实现真正意义上做到让农民“吹着空调种地”。
      </p> -->
      <p class="describe">
        {{ GraphicData.content }}
      </p>
      <!-- 机器人模型展示 -->
      <div class="showModel">
        <div class="product">
          <div
            class="product_dt"
            @click="toProductLine(ind)"
            v-for="(ite, ind) in GraphicData.pictureMiddleList"
            :key="ind"
          >
            <img :src="ite.mediaUrl" alt="" />
            <p>{{ ite.mediaName }}</p>
          </div>
          <!-- <div class="product_dt" @click="toProductLine(1)">
            <img src="../../assets//img_home/datian_s.png" alt="" />
            <p>大田系列</p>
          </div>
          <div class="product_gl" @click="toProductLine(2)">
            <img src="../../assets//img_home/guolin_s.png" alt="" />
            <p>果林系列</p>
          </div>
          <div class="product_sd" @click="toProductLine(3)">
            <img src="../../assets//img_home/shandi_s.png" alt="" />
            <p>山地系列</p>
          </div> -->
        </div>
      </div>
      <!-- 展示 -->
      <div class="showingStand">
        <!-- 核心优势 -->
        <div
          class="kernel"
          v-for="(items, indexs) in GraphicData.pictureLastList"
          @click="toCoreAdvantage(indexs)"
          :key="indexs"
        >
          <img class="kernel_img" :src="items.mediaUrl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { ElMessage } from "element-plus";
import { GetHomeCarousel, GetHomeImgText } from "@/api/api";
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    // 图文数据
    const GraphicData = ref({
      title: "", //标题
      content: "", //正文
      pictureLastList: [], //核心优势 新闻资讯 图片
      pictureMiddleList: [], //产品系列图
    });
    // const content = ref('')
    // const title = ref('')

    let introductory = ref(
      "四大板块Farmbot分别为大田AI全数字器人作业生态系统、果园AI机器人全数字作业生态系统、蔬菜AI机器人全数字作业生态系统和特种山地作业AI机器人生态系统，系统可以实现真正意义上做到让农民“吹着空调种地”。"
    );
    onMounted(() => {
      GetHomeImgText().then((res) => {
        // console.log(res.data);
        if (res && res.code == 0) {
          const val = res.data.mediaSortVOMiddle;
          for (let i = 0; i < val.length; i++) {
            console.log(val[i].mediaName);
            // val[i].mediaName = val[i].mediaName.match(/(.*?)_\d+\.png/)[1];
            if (val[i].mediaName.slice(-3) == "png") {
              val[i].mediaName = val[i].mediaName.match(/(.*?)_\d+\.png/)[1];
            } else if (val[i].mediaName.slice(-3) == "jpg") {
              val[i].mediaName = val[i].mediaName.match(/(.*?)_\d+\.jpg/)[1];
            } else {
              val[i].mediaName = val[i].mediaName.match(/(.*?)_\d+\.gif/)[1];
            }
          }

          GraphicData.value.title = res.data.articleDTO.title;
          GraphicData.value.content = res.data.articleDTO.content;
          GraphicData.value.pictureLastList = res.data.mediaSortVOLast;
          GraphicData.value.pictureMiddleList = val;
          // console.log(GraphicData);
          // console.log(content.value)
          // console.log(title.value)
        } else {
          if (res.msg) {
            ElMessage.error(res.msg);
          } else if (res.data.msg) {
            ElMessage.error(res.data.msg);
          }
        }
      });
    });
    // run
    function toCoreAdvantage(num) {
      console.log(num);
      if (num == 0) {
        // 核心优势
        router.push({ path: "/CoreAdvantage" });
      } else if (num == 1) {
        // 视频中心
        router.push({ path: "/MediaCenter" });
      }
    }

    // 产品系列
    function toProductLine(e) {
      console.log(route.path, "获取当前路由");
      router.push({
        path: "/ProductLine",
        query: {
          scrollTo: e,
        },
      });
    }

    return {
      toCoreAdvantage,
      toProductLine,
      GraphicData,
    };
  },
});
</script>
<style lang="less" scoped>
@import "@/components/styles/home/projectCom.less";
</style>
